import React, { useEffect, useState, useMemo, forwardRef, useCallback } from "react";
import axios from "axios";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../../constants/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const API_URL = process.env.REACT_APP_API_URL;

const Programmatic = () => {
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [companyName, setCompanyName] = useState("Company");
  const [selectedDate, setSelectedDate] = useState(new Date());

  let d = useMemo(() => new Date(), []);
  const lastMonth = useMemo(() => d.setMonth(d.getMonth() - 1), [d]);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const tableHeadings1 = [
    { name: "Date", key: 'item_date' },
    { name: "Campaign", key: 'campaign' },
    { name: "Cost", key: 'cost' },
    { name: "Impressions", key: 'impressions' },
    { name: "CPM", key: 'cpm' },
    { name: "CTR", key: 'ctr' },
    { name: "Clicks", key: 'clics' },
    { name: "eCPC", key: 'ecpc' },
    { name: "Total Conversions", key: 'total_conversions' },
    { name: "CPA", key: 'cpa' },
    { name: "PV Conversions", key: 'pv_conversions' },
    { name: "PC Conversions", key: 'pc_conversions' },
    { name: "REG", key: 'reg' },
    { name: "Login", key: 'login' },
    { name: "FTD", key: 'ftd' },
    { name: "DC", key: 'dc' },
    { name: "CPA REG", key: 'cpa_reg' },
    { name: "CPA FTD", key: 'cpa_ftd' },
    { name: "CPA DC", key: 'cpa_dc' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSpinner(true);
        const response = await axios.post(`${API_URL}/trade-desk`, {
          year: selectedDate.getFullYear(),
          month: selectedDate.getMonth() + 1
        });

        const flattenedData = response.data.map(item => ({
          id: item.id,
          item_date: item.item_date,
          competitor: item.companies_id?.name || "N/A",
          campaign: item.campaigns_id?.name || "N/A",
          cost: item.cost.toLocaleString(),
          impressions: item.impressions.toLocaleString(),
          cpm: item.cpm.toFixed(2),
          ctr: (item.ctr * 100).toFixed(2) + '%',
          clics: item.clics.toLocaleString(),
          ecpc: item.ecpc.toFixed(2),
          total_conversions: item.total_conversions.toLocaleString(),
          cpa: item.cpa.toFixed(2),
          pv_conversions: item.pv_conversions.toLocaleString(),
          pc_conversions: item.pc_conversions.toLocaleString(),
          reg: item.reg,
          ftd: item.ftd,
          dc: item.dc,
          cpa_reg: item.cpa_reg.toFixed(2),
          cpa_ftd: item.cpa_ftd.toFixed(2),
          cpa_dc: item.cpa_dc.toFixed(2),
        }));

        setData(flattenedData);

        if (flattenedData.length > 0) {
          const firstRow = flattenedData[0];
          setCompanyName(firstRow.competitor);
        }
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      } finally {
        setSpinner(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formatDate = useCallback((date, withDay) => {
    if (date) {
      const day = withDay && (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate());
      const month =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`;
      const year = date.getFullYear();
      return `${year}-${month}${day ? `-${day}` : ''}`;
    } else {
      const month =
        lastMonth.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`;
      const year = lastMonth.getFullYear();

      return `${year}-${month}`;
    }
  }, [lastMonth]);


  const icon = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%"
        }}
      >
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6.33325H2.66667V7.66659H4V6.33325ZM6.66667 6.33325H5.33333V7.66659H6.66667V6.33325ZM9.33333 6.33325H8V7.66659H9.33333V6.33325ZM10.6667 1.66659H10V0.333252H8.66667V1.66659H3.33333V0.333252H2V1.66659H1.33333C0.593333 1.66659 0.00666666 2.26659 0.00666666 2.99992L0 12.3333C0 13.0666 0.593333 13.6666 1.33333 13.6666H10.6667C11.4 13.6666 12 13.0666 12 12.3333V2.99992C12 2.26659 11.4 1.66659 10.6667 1.66659ZM10.6667 12.3333H1.33333V4.99992H10.6667V12.3333Z"
            fill="#23244D"
            opacity="1"
          />
        </svg>
        <span>{formatDate(selectedDate)}</span>
      </div>
    );
  };


  const ExampleCustomInput = forwardRef((props, ref) => {
    return (
      <button {...props} onClick={props.onClick}>
        {icon()}
      </button>
    );
  });

  return (
    <div className="App atl competitors-1">
      <div className="page-content-container">
        <div className="title-container">
          <h2 className="title overview-title">
            {companyName}, Programmatic, {monthNames[selectedDate.getMonth()]} {selectedDate.getFullYear()}
          </h2>
        </div>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          showMonthYearPicker
          dateFormat="MMMM yyyy"
          wrapperClassName={`timezone2-date-picker`}
          showPopperArrow={false}
          popperPlacement="bottom-end"
          customInput={<ExampleCustomInput className={"example-custom-input-1"} style={{ backgroundColor: "white" }} />}
        />

        <div className="chart-container table" style={{ borderRadius: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {spinner ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Spinner
                  radius={40}
                  color={TABLE_TEXT_COLOR}
                  stroke={3}
                  visible={true}
                />
                <p>Loading data...</p>
              </div>
            ) : (
              data && data.length > 0 ? (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                  <tr>
                    {tableHeadings1.map((heading, index) => (
                      <th key={index} style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                        {heading.name}
                      </th>
                    ))}
                  </tr>
                  </thead>
                  <tbody>
                  {data.map((row, rowIndex) => (
                    <tr key={rowIndex} style={{ display: "table-row" }}>
                      {tableHeadings1.map((heading, colIndex) => (
                        <td
                          key={colIndex}
                          style={{
                            display: "table-cell",
                            borderBottom: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {row[heading.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>
              ) : (
                <p style={{ textAlign: "center", padding: "20px" }}>
                  No data available.
                </p>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programmatic;
